import firebase from 'firebase';
require("firebase/database");
require("firebase/auth");

const config = {

  apiKey: "AIzaSyCGn1RgU84D6jOm9jh5sOw8AUz5t1TRIOI",
  authDomain: "kido-village-backend.firebaseapp.com",
  databaseURL: "https://kido-village-backend-default-rtdb.firebaseio.com",
  projectId: "kido-village-backend",
  storageBucket: "kido-village-backend.appspot.com",
  messagingSenderId: "364505640965",
  appId: "1:364505640965:web:7fc16a0263becc66732c6c"

};

const fire = firebase.initializeApp(config);

export const auth = firebase.auth();

export const storage = firebase.storage();


export default fire;