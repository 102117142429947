import React, { useState, useEffect, useContext} from 'react';
import firebase from '../../config/Fire';
import { storage } from "../../config/Fire";
import Axios from "axios";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import { AuthContext } from '../../context/Auth';
import $ from 'jquery';
import latlongList from "./pincodes_lat_long.json";



function Home(props) {


  const { currentUser } = useContext(AuthContext);

  const [teachersProfileData, setTeachersProfileData] = useState("");

  
  useEffect(() => {
    localStorage.setItem('latlongList', JSON.stringify(latlongList));
  });

  useEffect(() => {
    getTeacherProfileData();
  }, []);

  const getTeacherProfileData = ()=>{

    var axios = require('axios');
    var FormData = require('form-data');
    var data = new FormData();
    data.append('api', 'get_teachers');
    data.append('admin', 'ziauddin.sayyed@kido.school');
    data.append('filter', '{}');
  
    var config = {
      method: 'post',
      url: 'https://shop.kidovillage.com/kvshop_api/api.php',
      data : data
    };
  
    axios(config)
    .then(function (response) {
      setTeachersProfileData((response.data));
      // console.log(response.data);
    })
    .catch(function (error) {
      // console.log(error);
    });
  }


  var acts = 0 ;var inacts = 0;

  Object.entries(teachersProfileData).map((item) => (
    item[1].status == "1" ? acts++ : inacts++
    //  var totaltecher = Object.keys(teachersProfileData).length
  ))

  return(
    <>
     <Navbar />
    <div className="wrapper d-flex align-items-stretch">
    <Sidebar />
  
    <div className="container main bg-light py-5">
        <h1>Kido Village Dashboard</h1>
        <div className="row justify-content-center py-4">
            <div className="col-md-4">
              <div className="widgets-box border rounded-lg shadow text-center p-5">
                <h3>Total Numbers of <br/>Teachers</h3>
                <h2 className="widgets-text text-info">{acts + inacts}</h2>
              </div>
            </div>
            <div className="col-md-4">
              <div className="widgets-box border rounded-lg shadow text-center p-5">
                  <h3>Total Numbers of Active Teachers</h3>
                  <h2 className="widgets-text text-success">{acts}</h2>
              </div>
            </div>
            <div className="col-md-4">
              <div className="widgets-box border rounded-lg shadow text-center p-5">
                  <h3>Total Numbers of In Active Teachers</h3>
                  <h2 className="widgets-text text-danger">{inacts}</h2>
              </div>
            </div>
         </div>
    </div>

    </div>
  </>
  );

}

export default Home;
