import React, { useState, useEffect, useContext} from 'react';
import firebase from '../../config/Fire';
import { storage } from "../../config/Fire";
import Axios from "axios";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import Imgnotfound from '../../assets/image-not-found.svg';
import $ from 'jquery';


function TeacherProfile(props) {

  const [showModal, setShowModal] = useState(false);
  const [teachersProfileData, setTeachersProfileData] = useState("");
  const [cityData, setCityData] = useState("");
  // const [profileDet, setProfileDet] = useState(false);

  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [upload_aadhar, setUpload_aadhar] = useState("");
  const [upload_cv, setUpload_cv] = useState("");
  const [upload_image, setUpload_image] = useState("");
  const [police_verification, setPolice_verification] = useState("");
  const [place_image_1, setPlace_image_1] = useState("");
  const [place_image_2, setPlace_image_2] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");
  const [position_of_teacher, setPosition_of_teacher] = useState("");
  const [kiddo_rating, setKiddo_rating] = useState("");
  const [parent_rating, setParent_rating] = useState("");
  const [about_teacher, setAbout_teacher] = useState("");

  const [teacherProfileId, setTeacherProfileId] = useState("");
 
  const res_pre = "https://kidovillage.com/";
  const [editDetails, setEditDetails] = useState(false);

  const latlongListing = JSON.parse(localStorage.getItem("latlongList"));


  // var profileDet = {};

  useEffect(() => {
    getTeacherProfileData();
    filterStatus();
  }, []);
  

  const getTeacherProfileData = ()=>{

  var axios = require('axios');
  var FormData = require('form-data');
  var data = new FormData();
  data.append('api', 'get_teachers');
  data.append('admin', 'ziauddin.sayyed@kido.school');
  data.append('filter', '{}');

  var config = {
    method: 'post',
    url: 'https://shop.kidovillage.com/kvshop_api/api.php',
    data : data
  };

  axios(config)
  .then(function (response) {
    setTeachersProfileData((response.data));
    // console.log(response.data);
  })
  .catch(function (error) {
    // console.log(error);
  });
}


function setCities(st_id,city=null) {
  var axios = require('axios');
  var FormData = require('form-data');
  var data = new FormData();
  data.append('api', 'get_city_by_state');
  data.append('filter', '{"state_id":'+st_id+'}');
  axios({method: 'post',url: 'https://shop.kidovillage.com/kvshop_api/api.php',data : data})
  .then((response) => {
      setCityData(response.data);
      if(city){
        $("#city").val(city);
      }
      // console.log(response.data);
  }).catch((error) => console.log(error));  
}

const handleSelectStateChange = (event)=>{
  // setSelectState(event.target.value)
  // var st_id = ;
  setCities(event.target.value);
}

$(document).ready(function() {

$('#upload_aadhar').on('change',function(){
  //get the file name
  var fileName = $(this).val();
  //replace the "Choose a file" label
  var current_path = fileName.split("\\").pop();
  $(this).next('.custom-file-label').html(current_path);
})

$('#upload_cv').on('change',function(){
  //get the file name
  var fileName = $(this).val();
  //replace the "Choose a file" label
  var current_path = fileName.split("\\").pop();
  $(this).next('.custom-file-label').html(current_path);
})

$('#place_image1').on('change',function(){
  //get the file name
  var fileName = $(this).val();
  //replace the "Choose a file" label
  var current_path = fileName.split("\\").pop();
  $(this).next('.custom-file-label').html(current_path);
})

$('#place_image2').on('change',function(){
  //get the file name
  var fileName = $(this).val();
  //replace the "Choose a file" label
  var current_path = fileName.split("\\").pop();
  $(this).next('.custom-file-label').html(current_path);
})

$('#upload_image').on('change',function(){
  //get the file name
  var fileName = $(this).val();
  //replace the "Choose a file" label
  var current_path = fileName.split("\\").pop();
  $(this).next('.custom-file-label').html(current_path);
})

$('#police_verification').on('change',function(){
  //get the file name
  var fileName = $(this).val();
  //replace the "Choose a file" label
  var current_path = fileName.split("\\").pop();
  $(this).next('.custom-file-label').html(current_path);
})

});

// var server = ((document.location.host).indexOf("localhost") !== -1) ? "http://localhost/apis/api.php" : "https://shop.kidovillage.com/kvshop_api/api.php";

var server = "https://shop.kidovillage.com/kvshop_api/api.php";
 // On Load

 const teacherpformSubmit = (event)=>{

      event.stopPropagation();
      event.preventDefault();
  
      var form = new FormData();
      form.append("api", "upload_file");

      if(latlongListing[$("#area").val()]){
        var lat = latlongListing[$("#area").val()].lat;
        var long = latlongListing[$("#area").val()].long;
      }else{
        var lat = "0.00";
        var long = "0.00";
      }

      if($("#upload_aadhar")[0].files.length){form.append("aadhar", $("#upload_aadhar")[0].files[0])};
      if($("#upload_cv")[0].files.length){form.append("CV", $("#upload_cv")[0].files[0])};
      if($("#place_image1")[0].files.length){form.append("place_image_1", $("#place_image1")[0].files[0])};
      if($("#place_image2")[0].files.length){form.append("place_image_2", $("#place_image2")[0].files[0])};
      if($("#upload_image")[0].files.length){form.append("upload_image", $("#upload_image")[0].files[0])};
      if($("#police_verification")[0].files.length){form.append("police_verification", $("#police_verification")[0].files[0])};
      // var user = local_get('user');
      if($("#sbmt_btn").attr("is_edit") == "yes"){
          form.append("id", $("#teach_profile").attr("teach_id"));
          // $("#teach_profile").attr("teach_id",teacherProfileId);
      }
      form.append("name", $("#name").val());
      form.append("contact", $("#contact").val());
      form.append("address1", $("#address1").val());
      form.append("address2", $("#address2").val());
      form.append("state", $("#state").val());
      form.append("city", $("#city").val());
      form.append("area", $("#area").val());
      form.append("position_of_teacher", $("#position_of_teacher").val());
      form.append("about_teacher", $("#about_teacher").val());
      form.append("email",$("#email").val());
      form.append("status",$("#status").val()); 
      form.append("latitude", lat);
      form.append("longitude", long);
      form.append("kiddo_rating",$("#kido_rating").val() || 0);
      form.append("parent_rating",$("#parent_rating").val() || 0);


      var settings = {
      "url": server,
      "method": "POST",
      "timeout": 0,
      "processData": false,
      "mimeType": "multipart/form-data",
      "contentType": false,
      "data": form,
      success: function (response) {
      if($("#sbmt_btn").attr("is_edit") == "yes"){
        alert("Teacher profile updated succesfully");
      }else{
        alert("Teacher profile added succesfully");
      }
        window.location.reload();
          // console.log(response);
      },
      error: function (xhr, ajaxOptions, thrownError) {
          alert(xhr.status);
          alert(thrownError);
      }
      };

      $.ajax(settings);

  }

const handleEdit = (
  name,
  contact,
  email,
  status,
  upload_aadhar,
  upload_cv,
  upload_image,
  police_verification,
  place_image_1,
  place_image_2,
  address1,
  address2,
  state,
  city,
  area,
  position_of_teacher,
  kiddo_rating,
  parent_rating,
  about_teacher,
  teacherProfileId,
  e
) => {
  setShowModal(true);
  setEditDetails(true);

  setName(name);
  setContact(contact);
  setEmail(email);
  setStatus(status);
  setUpload_aadhar(upload_aadhar);
  setUpload_cv(upload_cv);
  setUpload_image(upload_image);
  setPolice_verification(police_verification);
  setPlace_image_1(place_image_1);
  setPlace_image_2(place_image_2);
  setAddress1(address1);
  setAddress2(address2);
  setState(state);
  setCity(city);
  setArea(area);
  setPosition_of_teacher(position_of_teacher);
  setKiddo_rating(kiddo_rating);
  setParent_rating(parent_rating);
  setAbout_teacher(about_teacher);
  // setTeacherProfileId(teacherProfileId);
  // teacherProfileId
  // $("#exampleModal").attr("tech-id");
  $("#teach_profile").attr("teach_id",teacherProfileId);
  // console.log($("#teach_profile").attr("teach_id"));
  setCities(state,city);

};

const filterStatus = () => {
  $('.filter-status').on('change', function() {
      // alert( this.value);
      $('.job-open').css("display","none");
      $('.'+this.value).css("display","table-row");
      if(this.value == "all"){
        $('.job-open').css("display","table-row");
      }
  });
}

$(document).ready(function(){
  $("#search-input").on("keyup", function() {
    var value = $(this).val().toLowerCase();
    $("#teacher-profile-table tr").filter(function() {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
    });
  });
});

const  modalCloseHandler = () => { setShowModal(false); setEditDetails(false)};

let modalContent = showModal ? 

  (
  <>
     <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-keyboard="false" data-backdrop="static">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{editDetails ? "Edit Teacher Profile" : "Add Teacher Profile"}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={modalCloseHandler}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
          <div className="modal-body">
       <form onSubmit={teacherpformSubmit} id="formvalidate" enctype="multipart/form-data">
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="name">Name</label>
                    <input type="text" className="form-control" id="name" defaultValue={editDetails ? name : ""} name="name" placeholder="Enter Your Name"/>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="contact">Contact</label>
                  <input type="number" className="form-control" id="contact" defaultValue={editDetails ? contact : ""} name="contact"  placeholder="Enter Your Contact Number"/>
                </div>
            </div> 
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="name">Email</label>
                    <input type="email" className="form-control" id="email" defaultValue={editDetails ? email : ""} name="email" placeholder="Enter Your Name"/>
                </div>  
                <div className="form-group col-md-6">
                    <label htmlFor="status">Status</label>
                    <select name="status" className="form-control" id="status" defaultValue={editDetails ? status : ""}>
                        <option>Select status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                    </select>
                </div>
            </div>  
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="upload_aadhar">Adhar Card Image <small className="text-warning">Note: image should be in png or jpg</small></label>
                    <div className="custom-file">
                    <input type="file" className="custom-file-input" name="upload_aadhar" id="upload_aadhar"/>
                    <label className="custom-file-label" htmlFor="upload_aadhar">{editDetails ? (upload_aadhar == null ? "No file uploaded" : upload_aadhar.substring(48, 100)) : "upload adhar"}</label>
                    {/* <img src={editDetails ? res_pre+upload_aadhar : ""} width="50" height="50"/> */}
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="upload_cv">CV File <small className="text-warning">Note: file should be in Pdf</small></label>
                    <div className="custom-file">
                    <input type="file" className="custom-file-input" name="upload_cv" id="upload_cv"/>
                    <label className="custom-file-label" htmlFor="upload_cv">{editDetails ? (upload_cv == null ? "No file uploaded" : upload_cv.substring(48, 100)) : "upload CV"}</label>
                    </div>
                </div>
            </div> 
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="upload_image">Profile Picture <small className="text-warning">Note: image should be in png or jpg</small></label>
                    <div className="custom-file">
                    <input type="file" className="custom-file-input" name="upload_image" id="upload_image"/>
                    <label className="custom-file-label" htmlFor="upload_image">{editDetails ? (upload_image == null ? "No file uploaded" : upload_image.substring(48, 100)) : "Upload Profile Picture"}</label>
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="police_verification">Police verification <small className="text-warning">Note: image should be in png or jpg</small></label>
                    <div className="custom-file">
                    <input type="file" className="custom-file-input" name="police_verification" id="police_verification"/>
                    <label className="custom-file-label" htmlFor="police_verification">{editDetails ? (police_verification == null ? "No file uploaded" : police_verification.substring(48, 100)) : "Upload Police verification"}</label>
                    </div>
                </div>
            </div>  
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="place_image1">POD Image1 <small className="text-warning">Note: image should be in png or jpg</small></label>
                    <div className="custom-file">
                    <input type="file" className="custom-file-input" name="place_image1" id="place_image1"/>
                    <label className="custom-file-label" htmlFor="place_image1">{editDetails ? (place_image_1 == null ? "No file uploaded" : place_image_1.substring(32, 100)) : "Upload PDO Image1"}</label>
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="place_image2">POD Image2 <small className="text-warning">Note: image should be in png or jpg</small></label>
                    <div className="custom-file">
                    <input type="file" className="custom-file-input" name="place_image2" id="place_image2"/>
                    <label className="custom-file-label" htmlFor="place_image2">{editDetails ? (place_image_2 == null ? "No file uploaded" : place_image_2.substring(32, 100)) : "Upload PDO Image2"}</label>
                    </div>
                </div>
            </div> 
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="address1">Address Line 1</label>
                    <input type="text" className="form-control" id="address1" name="address1" defaultValue={editDetails ? address1 : ""} placeholder="Enter Your Address Line 1"/>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="address2">Address Line 2</label>
                  <input type="text" className="form-control" id="address2" name="address2" defaultValue={editDetails ? address2 : ""}  placeholder="Enter Your Address Line 2"/>
                </div>
            </div>   
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="stateselect">Select State</label>
                    <select name="state" className="form-control state" id="state" defaultValue={editDetails ? state : ""}
                     onChange={handleSelectStateChange}>
                        <option value="0">Select any state</option>
                        <option value="1">ANDHRA PRADESH</option>
                        <option value="2">ASSAM</option>
                        <option value="3">ARUNACHAL PRADESH</option>
                        <option value="4">BIHAR</option>
                        <option value="5">GUJRAT</option>
                        <option value="6">HARYANA</option>
                        <option value="7">HIMACHAL PRADESH</option>
                        <option value="8">JAMMU &amp; KASHMIR</option>
                        <option value="9">KARNATAKA</option>
                        <option value="10">KERALA</option>
                        <option value="11">MADHYA PRADESH</option>
                        <option value="12">MAHARASHTRA</option>
                        <option value="13">MANIPUR</option>
                        <option value="14">MEGHALAYA</option>
                        <option value="15">MIZORAM</option>
                        <option value="16">NAGALAND</option>
                        <option value="17">ORISSA</option>
                        <option value="18">PUNJAB</option>
                        <option value="19">RAJASTHAN</option>
                        <option value="20">SIKKIM</option>
                        <option value="21">TAMIL NADU</option>
                        <option value="22">TRIPURA</option>
                        <option value="23">UTTAR PRADESH</option>
                        <option value="24">WEST BENGAL</option>
                        <option value="25">DELHI</option>
                        <option value="26">GOA</option>
                        <option value="27">PONDICHERY</option>
                        <option value="28">LAKSHDWEEP</option>
                        <option value="29">DAMAN &amp; DIU</option>
                        <option value="30">DADRA &amp; NAGAR</option>
                        <option value="31">CHANDIGARH</option>
                        <option value="32">ANDAMAN &amp; NICOBAR</option>
                        <option value="33">UTTARANCHAL</option>
                        <option value="34">JHARKHAND</option>
                        <option value="35">CHATTISGARH</option>
                    </select>
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="cityselect">Select City</label>
                    <select name="city" className="form-control" id="city" defaultValue={editDetails ? city : ""}>
                        <option value="0">Select any City</option>
                        {Object.entries(cityData).map((item) => {
                            return(
                        <option value={item[1].id}>{item[1].name}</option>
                            )
                            })}
                    </select>
                </div>
            </div>  
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="area">Post Code</label>
                    <input type="number" className="form-control" id="area" name="area" defaultValue={editDetails ? area : ""} placeholder="Enter Your Post Code" required/>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="position_of_teacher">Position </label>
                  <input type="text" className="form-control" id="position_of_teacher" name="position_of_teacher"  defaultValue={editDetails ? position_of_teacher : ""} placeholder="Enter Your Position"/>
                </div>
            </div>  
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="kido_rating">Kido Rating</label>
                    <input type="number" className="form-control" id="kido_rating" name="kido_rating" defaultValue={editDetails ? kiddo_rating : ""} placeholder="Enter Kido Rating"/>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="parent_rating">Parent Rating</label>
                  <input type="number" className="form-control" id="parent_rating" name="parent_rating"  defaultValue={editDetails ? parent_rating : ""} placeholder="Enter Parent Rating"/>
                </div>
            </div>  
            <div className="form-group">
                <label htmlFor="about_teacher">About</label>
                <textarea type="text" className="form-control" id="about_teacher" name="about_teacher" defaultValue={editDetails ? about_teacher : ""} placeholder="Enter about_teacher"></textarea>
            </div>
            
            <button type="submit" className="btn btn-primary btn-sm" id="sbmt_btn" is_edit={editDetails ? "yes" : "no"} >Submit</button>
          </form>
          </div>
          </div>
        </div>
      </div>
  </>
  )
  :null;
 

  return(
    <>
     <Navbar />
    <div className="wrapper d-flex align-items-stretch">
    <Sidebar />
  
    <div className="container-fluid main bg-light py-5">
        <div className="row justify-content-center">
            <div className="col-lg-11">

            <div className="add-teacher-profile pb-3">
                  <div className="d-flex justify-content-between">
                    <div className="title">
                        <h2 id="teach_profile">Teacher's profile</h2>
                        {/* <p>International Early Years Programs from Zero to Six. At Home and Online</p> */}
                      </div>
                      <div className="add-post-button">
                        <button onClick={() => setShowModal(true)} className="btn btn-dark btn-sm" data-toggle="modal" data-target="#exampleModal"><i className="fas fa-plus"></i></button>
                      </div>
                  </div>

                  <div className="m-content">
                      {modalContent}
                  </div>
                  
                </div>

                <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputEmail4">Search based on Name</label>
                  <input type="text" className="form-control" placeholder="search data" id="search-input"/>
                </div>
                <div class="form-group col-md-6">
                  <label for="inputPassword4">Search based on status</label>
                  <select id="inputState" className="filter-status form-control">
                    <option value="all">All Teachers</option>
                    <option value="1">Active Teachers</option>
                    <option value="0">In Active Teachers</option>
                  </select>
                </div>
              </div>

            <table className="table table-striped table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Contact</th>
                  <th scope="col">Adhar</th>
                  <th scope="col">CV</th>
                  <th scope="col">Profile</th>
                  <th scope="col">POD 1</th>
                  <th scope="col">POD 2</th>
                  <th scope="col">Status</th>
                  {/* <th scope="col">Action</th> */}
                </tr>
              </thead>
              <tbody id="teacher-profile-table">
              {Object.entries(teachersProfileData).sort((a, b) => a.timeM < b.timeM ? 1:-1).map((item) => (
                // var x = {item[1].status}
                <tr key={item[0]} className={"job-open "+ item[1].status}>
                  <td>{item[1].name}</td>
                  <td data-tid={item[1].id}><a onClick={(e) =>
                        handleEdit(
                        item[1].name,
                        item[1].contact,
                        item[1].email,
                        item[1].status,
                        item[1].upload_aadhar,
                        item[1].upload_cv,
                        item[1].upload_image,
                        item[1].police_verification,
                        item[1].place_image_1,
                        item[1].place_image_2,
                        item[1].address1,
                        item[1].address2,
                        item[1].state,
                        item[1].city,
                        item[1].area,
                        item[1].position_of_teacher,
                        item[1].kiddo_rating,
                        item[1].parent_rating,
                        item[1].about_teacher,
                        item[1].id,
                        e
                        )} data-toggle="modal" data-target="#exampleModal">{item[1].email}</a></td>
                  <td>{item[1].contact}</td>
                  <td><img width="50" height="50" src={item[1].upload_aadhar ?  res_pre+item[1].upload_aadhar : Imgnotfound}/></td>
                  <td> <a  href={item[1].upload_cv ? res_pre+item[1].upload_cv : "#"} > {item[1].upload_cv ? <i className="fas fa-file-download fa-3x"></i> : <img src={Imgnotfound} width="50" height="50"/>} </a> </td>
                  <td><img width="50" height="50" src={item[1].upload_image ? res_pre+item[1].upload_image : Imgnotfound}/></td>
                  <td><img width="50" height="50" src={item[1].place_image_1 ? res_pre+item[1].place_image_1 : Imgnotfound}/></td>
                  <td><img width="50" height="50" src={item[1].place_image_2 ? res_pre+item[1].place_image_2 : Imgnotfound}/></td>
                  <td>{item[1].status == "1" ? "Active" : "Inactive"}</td>
                  {/* <td>
                    <i className="fas fa-eye text-info"></i>
                    <i className="fas fa-pencil-alt text-success pl-2"></i>
                    <i className="fas fa-trash-alt text-danger pl-2"></i>
                  </td> */}
                </tr>
              ))
              }
              </tbody>
            </table>

    
            </div>
         </div>
    </div>

    </div>
  </>
  );

}

export default TeacherProfile;
